// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/ui/molecules/DataTable/utils.ts"
);
import.meta.hot.lastModified = "1726176605639.3848";
}
// REMIX HMR END

import { type SortingState, type PaginationState } from '@tanstack/react-table'

import { type TableFilter } from '~/api'

export type SearchParams = {
  filters: Record<string, TableFilter[]>
  sorting: Record<string, SortingState>
  pagination: Record<string, PaginationState>
}

export function parseSearchParams(params: URLSearchParams): SearchParams {
  // TODO add typeguards here instead of potentially unsafe type assertions.
  const filters = JSON.parse(
    decodeURIComponent(params.get('filters') ?? '{}'),
  ) as Record<string, TableFilter[]>
  const sorting = JSON.parse(
    decodeURIComponent(params.get('sorting') ?? '{}'),
  ) as Record<string, SortingState>
  const pagination = JSON.parse(
    decodeURIComponent(params.get('pagination') ?? '{}'),
  ) as Record<string, PaginationState>
  return { filters, sorting, pagination }
}
