// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/config.ts"
);
import.meta.hot.lastModified = "1726176605615.3848";
}
// REMIX HMR END

// TODO: move all of this to @lib/constants.ts
export const TOAST_MS_TIMEOUT = 10000
export const CELL_PADDING = 16
export const CELL_MAX_W = 512
export const CELL_HEADER_SORT_W = 64
export const CELL_HEADER_ICON_W = 16 + 2
export const CELL_MIN_HEADER_LENGTH = 5
export const CHAR_W = 8
// Default pagination page size for node data
export const DEFAULT_PAGE_SIZE = 100
export const DEFAULT_CONVERSATION_NAME = 'Untitled conversation'
